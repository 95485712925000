$font-family: 'Camphor W01', 'Camphor W02', sans-serif;


html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 3rem 2rem 8rem;
  text-align: center;
  color: #333333;
  font-family: $font-family;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
}

body:has(.advertiser-portal) {
  background-color: transparent;
}

header {
  margin: 0 auto;
  max-width: 108.6rem;

  img {
    max-width: 25rem;
  }
}

main {

  & > div {
    margin: 0 auto;
    max-width: 108.6rem;


    & > p {
      font-size: 2rem;
      line-height: 3rem;
      margin: 1.5rem 3rem 0;

      em {
        font-weight: bold;
        display: block;
        margin-top: -.2rem;
      }
    }
  }
}

h1 {
  font-size: 3.4rem;
  margin: 1.4rem 1rem 0;
  line-height: 4.1rem;
  letter-spacing: -.5px;
}

ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: .4rem 0 0;
  padding: 0;
  justify-content: center;

  li {
    width: 30rem;
    margin: 5rem 3.1rem 0;
    background-color: white;
    border-radius: .6rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;


    div {
      position: relative;


      &:nth-child(1) {
        display: flex;
        justify-content: center;
        min-height: 16rem;
        flex-grow: 0;

        & > img {
          max-width: 100%;
          max-height: 16rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        & > div {
          background-color: rgba(255, 255, 255, .9);
          width: 17.5rem;
          height: 6.7rem;
          position: absolute;
          bottom: 0;
          margin: 0 auto;
          left: 0;
          right: 0;
          border-top-left-radius: .4rem;
          border-top-right-radius: .4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: unset;


          img {
            max-width: 15rem;
            max-height: 5rem;
          }
        }
      }

      &:nth-child(2) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        strong {
          color: white;
          height: 4.3rem;
          font-size: 1.7rem;
          line-height: 1.15em;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin: 0 -1.2rem;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

          span:first-child {
            margin: 0 1.2rem 0.17rem;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
          }

          span + span {
            background-color: inherit;
            position: absolute;
            left: 0;
            z-index: 1;
            bottom: -1.17rem;
            width: 1.2rem;
            height: 1.2rem;
            display: block;
            box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
            clip-path: polygon(100% 0, 100% 100%, 0 0);

            &:before {
              content: '';
              background: linear-gradient(to bottom, rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, 0) 100%);
              width: 1.2rem;
              height: 1.2rem;
              position: absolute;
              left: 0;
              top: 0;
            }

            & + span {
              left: auto;
              right: 0;
              clip-path: polygon(100% 0, 0% 100%, 0 0);
            }
          }
        }

        div {
          padding: .8rem 2.5rem 3.4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          p {
            padding: 1.3rem 0 0;
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: left;
            margin: 0;
          }

          button {
            border-radius: 2.15rem;
            border: solid 1px #3c763d;
            font-size: 1.7rem;
            font-weight: bold;
            font-family: $font-family;
            color: #3c763d;
            padding: .9rem 1.5rem 1rem;
            margin-top: 2.1rem;
            background-color: #dff0d8;
            cursor: default;
            outline: none;

            &.loading {
              .spinner {
                display: flex;
              }

              .content {
                display: none;
              }

              &:hover {
                background-color: #dff0d8;
                cursor: default;
              }
            }

            &:not([disabled]) {

              cursor: pointer;

              &:hover {
                background-color: rgba(223, 240, 216, 0.75);
              }

              &:active {
                padding-top: 1rem;
                padding-bottom: 0.9rem;
              }

            }

            .spinner {
              display: none;
              // display: flex;
              justify-content: center;
              margin: .1rem;
            }

            .spinner > span {
              width: 1.8rem;
              height: 1.8rem;
              margin: 0 .4rem;
              background-color: #3c763d;
              border-radius: 2rem;
              display: inline-block;
              -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
              animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            }

            .spinner .bounce1 {
              -webkit-animation-delay: -0.32s;
              animation-delay: -0.32s;
            }

            .spinner .bounce2 {
              -webkit-animation-delay: -0.16s;
              animation-delay: -0.16s;
            }

            @keyframes sk-bouncedelay {
              0%, 80%, 100% {
                -webkit-transform: scale(0);
                transform: scale(0);
              }

              40% {
                -webkit-transform: scale(1.0);
                transform: scale(1.0);
              }
            }

          }
        }
      }
    }

    &.taken {

      & > div:last-child > div {
        padding-bottom: 2.7rem;
      }

      mark {
        padding: 0.5rem;
        background: none;
        font-size: 2rem;
        font-weight: bold;
        border: 1px dashed #cfcfcf;
        margin: 1rem 0;
        background-color: #ffffe5;
      }

      button {
        font-size: 1.5rem;
        padding: .6rem 2rem .7rem;
        width: fit-content;
        margin: .9rem auto 0;
        cursor: pointer;

        &:active {
          padding: .7rem 2rem .6rem;
        }

      }

      p {
        text-align: center !important;
        padding-top: 1.3rem !important;
      }

      a {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2rem;
        color: #333;
        text-decoration: none;
        margin: 2rem auto 0;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: .5rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

ul.activated {
  li {

    opacity: .3;

    div {
      div {
        button {
          cursor: default;

          &:not([disabled]) {
            &:hover {
              background-color: rgba(223, 240, 216, 0.75);
            }


            &:active {
              padding: .7rem 2rem .6rem;
            }
          }

        }
      }
    }

    &.taken {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 820px) {
  body {
    padding: 2rem 1rem 6rem;
  }
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 9px;
  }
}

@media only screen and (max-width: 450px) {
  main > div > p {
    margin-left: 1rem;
    margin-right: 1rem;
    line-height: 2.8rem;
  }

  h1 {
    font-size: 3rem;
  }

  ul li {
    margin-top: 4rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

}

@media only screen and (max-width: 360px) {
  html {
    font-size: 8.5px;
  }

  ul {
    li {
      zoom: 1;
    }
  }
}

// IE 11 Fixes
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ul li div:nth-child(2) strong {
    margin: 0;

    span + span {
      display: none;
    }
  }

  ul li div:nth-child(1) > img {
    width: auto;
  }


}

#portalLinkInput {
  font-size: 13px;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  position: relative;
  background-color: #e9ecef;
  opacity: 1;
  margin: 20px 5px;
  box-shadow: none;
  display: block;
  width: 100%;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.portalLinkBtn {
  border-radius: .25rem;
  line-height: 1.5;
  background-image: none;
  font-size: 13px;
  font-weight: 700;
  color: #595959;
  background-color: #f9f9f9;
  border: 1px solid #ced4db;
  padding: .7rem 1.2rem;
  position: relative;
  cursor: pointer;
}

#generatePortalLinkBtn {
  background-color: #43649e;
  border-color: #43649e;
  color: #fff;
}

#generatePortalLinkBtn:hover {
  background-color: #5c79ab !important;
  border-color: #5c79ab !important;
}

#contact-support {
  font-size: 1.2rem;

  a {
    display: inline;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: underline;
    color: #333;
    font-weight: normal;
  }
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  display: table !important;
  padding: 0 !important;
  min-height: fit-content !important;
  border-radius: 2.15rem;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  button {
    border-radius: 0 !important;
    font-size: 11px !important;
    margin-top: 0 !important;
    float: left !important;
    outline: none !important;
    cursor: pointer !important;
    transition: 0.3s !important;
    display: table-cell !important;
    width: 33.33% !important;
    height: 100% !important;
    border: none !important;
    background-color: #f1f1f1 !important;
    color: #404040 !important;
    margin-bottom: -2px;

    &:hover {
      background-color: #ddd;
    }

    &.active {
      background-color: #ccc !important;
    }
  }
}

.tabwrapper {
  padding: 0 !important;
  min-height: 0 !important;
  margin-top: 2.1rem;
}

.tabcontent {
  padding: 0 5px !important;
  margin-top: 15px;

  textarea {
    border: 1px solid #ccc;
    font-family: $font-family;
    font-size: 1.5rem;
    max-width: 100%;
  }
}

.shareButton {
  background-color: #d7ebff !important;
  color: #0071b2 !important;
  border-color: #0071b2 !important;

  &:hover {
    background-color: rgba(215, 235, 255, 0.75) !important;
    cursor: default;
  }
}
